/* eslint-disable no-undef */
// import "dotenv/config";
import axios from "axios";
import { toast } from "react-toastify";
import { SessionTool } from "utils/SessionTool";

let base = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: base + "/admin/v1",
  params: {
    apikey: process.env.REACT_APP_APIGEE_APIKEY,
  },
});

let isRefreshing = false;
let refreshPromise = null;

API.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      if (SessionTool.isTokenExpired(accessToken)) {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshPromise = SessionTool.renewSession().finally(() => {
            isRefreshing = false;
            refreshPromise = null;
          });
        }
        await refreshPromise;
      }
      config.headers["x-authorization"] =
        `Bearer ${localStorage.getItem("access_token")}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = SessionTool.renewSession().finally(() => {
          isRefreshing = false;
          refreshPromise = null;
        });
      }

      await refreshPromise;

      originalRequest.headers["x-authorization"] =
        `Bearer ${localStorage.getItem("access_token")}`;

      return API(originalRequest);
    }

    if (
      error?.response?.message &&
      error.response.message == "Allowed ips not found"
    ) {
      toast.error(
        "Seu endereço IP não tem permissão para acessar este serviço. Entre em contato com nossa equipe de suporte.",
        {
          toastId: "not-allowed-ip-notification",
        },
      );
      return;
    }

    const accessToken = localStorage.getItem("access_token");

    if (
      error?.response?.status &&
      error.response.status === 401 &&
      accessToken
    ) {
      toast.error("Sessão expirada! Redirecionando para tela de login...", {
        toastId: "unauthorized-notification",
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/auth/login";
      }, 3000);
      return;
    }

    if (error?.response?.status && error.response.status === 403) {
      toast.error("Você não possui permissão para acessar este recurso.", {
        toastId: "permission-denied-notification",
      });
      return;
    }

    return Promise.reject(error);
  },
);

export default API;
