/* eslint-disable no-undef */
import { toast } from "react-toastify";
import API from "service/api";
import newApi from "service/newApi";
import qualityApi from "service/qualityApi";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_APIGEE_APIKEY;

export class SessionTool {
  static isTokenExpired(token) {
    if (!token.split(".")[1]) return true;
    const payload = JSON.parse(atob(token.split(".")[1]));
    const exp = payload.exp;
    return Date.now() >= exp * 1000;
  }

  static async renewSession() {
    try {
      const accessToken = localStorage.getItem("access_token");
      const refreshToken = localStorage.getItem("refresh_token");

      if (!accessToken || !refreshToken) {
        toast.error("Sessão inválida! Redirecionando para tela de login...", {
          toastId: "invalid-session",
        });
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/auth/login";
        }, 3000);
        return;
      }

      const response = await fetch(
        `${API_URL}/auth/refresh?apikey=${API_KEY}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ refreshToken }),
        },
      ).catch(() => {
        toast.error("Sessão expirada! Redirecionando para tela de login...", {
          toastId: "unauthorized-notification",
        });
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/auth/login";
        }, 3000);
      });

      const { access_token, refresh_token } = await response.json();

      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);

      API.defaults.headers["x-authorization"] = `Bearer ${accessToken}`;
      qualityApi.defaults.headers["x-authorization"] = `Bearer ${accessToken}`;
      newApi.defaults.headers["x-authorization"] = `Bearer ${accessToken}`;
    } catch (error) {
      toast.error(
        "Erro ao renovar a sessão, por favor realize login novamente.",
        {
          toastId: "internal-error",
        },
      );
    }
  }
}
