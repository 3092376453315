import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Tooltip,
} from "reactstrap";
import { createSchema, updateSchema } from "schema/passwordSchema";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const UserForm = () => {
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const [userClient, setUserClient] = useState(null);
  const [availableClients, setAvailableClients] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [changePassword, setChangePassword] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const location = useLocation();

  const handleUpdatePassword = (e) => {
    if (e.target.value !== "") {
      setChangePassword(true);
    } else {
      setChangePassword(false);
    }
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const emptyUser = {
    id: "",
    username: "",
    client: {
      name: "",
      id: "",
    },
    resources: [],
  };
  const [user, setUser] = useState(emptyUser);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      hasId && !changePassword ? updateSchema : createSchema,
    ),
  });

  const onSubmit = (data) => {
    setLoading(true);

    if (!data.username.trim()) {
      toast.error("Usuário inválido!");
      return;
    }

    data.resources = [];
    data.roles = selectedRoles.map((role) => role.value);

    if (!userClient?.value) {
      toast.error("Selecione um cliente");
      return;
    }

    data.client_id = userClient.value;

    if (data.meuSputiExternalId) {
      data.resources.push({
        name: "meu-sputi",
        external_id: data.meuSputiExternalId.trim(),
      });
    }

    delete data.meuSputiExternalId;

    if (hasId) {
      api
        .put(`/users/${getParamsId()}`, data)
        .then(() => {
          toast.success("Usuário atualizado com sucesso!");
          setLoading(false);
        })
        .catch((error) => {
          console.error(error.response?.data?.message);
          toast.error("Erro ao atualizar Usuário!");
          setLoading(false);
        });
    } else {
      api
        .post("/users", data)
        .then(() => {
          toast.success("Usuário criado com sucesso!");
          setLoading(false);
          reset();
        })
        .catch((error) => {
          console.error(error.response?.data);
          if (error.response?.data?.message === "User already exists.") {
            toast.error(
              <div>
                Usuário já existe!{" "}
                <Link
                  to={`/admin/users-form?id=${error.response.data.user.id}`}
                >
                  Ver Usuário existente
                </Link>
              </div>,
            );
          } else if (
            error.response?.data?.message ===
            "ExternalId already registered in another user."
          ) {
            toast.error(
              "Meu Sputi - Identificador já registrado em outro usuário!",
            );
          } else {
            toast.error("Erro ao criar usuário!");
          }
          setLoading(false);
        });
    }
  };

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  const getRoles = async () => {
    await api
      .get(`/role`)
      .then((response) => response.data)
      .then((response) => {
        setAvailableRoles(
          response.roles.map((role) => ({ value: role.id, label: role.name })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar segmentos!");
        return;
      });
  };

  useEffect(() => {
    const id = getParamsId();

    if (!availableRoles.length) {
      getRoles();
    }

    api
      .get("/clients?limit=100")
      .then((response) => {
        setAvailableClients(
          response.data.clients.map((client) => ({
            value: client.id,
            label: client.name,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar clientes!");
        return;
      });

    if (id) {
      api
        .get(`/users/${id}`)
        .then((response) => {
          setUser(response.data.user);
          setUserClient({
            value: response.data.user.client.id,
            label: response.data.user.client.name,
          });
          setValue("username", response.data?.user?.username);

          if (response.data?.user?.roles?.length) {
            setSelectedRoles(
              response.data?.user?.roles.map((role) => ({
                value: role.id,
                label: role.name,
              })),
            );
          }

          if (response.data?.resources?.length) {
            for (const resource of response.data.resources) {
              if (resource.name === "meu-sputi")
                setValue("meuSputiExternalId", resource.external_id);
            }
          }
        })
        .catch(() => {
          toast.error("Erro ao recuperar usuários!");
          return;
        });
      setHasId(true);
    }
  }, [location.search]);

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="" xl="10">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  {hasId ? (
                    <h3 className="mb-0">Atualizar Usuário</h3>
                  ) : (
                    <h3 className="mb-0">Adicionar Usuário</h3>
                  )}
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="5" xl="5" sm="5">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Cliente
                        </label>

                        <Select
                          defaultValue={userClient}
                          name="colors"
                          options={availableClients}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Selecionar cliente"
                          value={userClient}
                          onChange={setUserClient}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Nome de Usuário
                        </label>
                        <input
                          className="form-control-alternative custom-input"
                          defaultValue={user.username}
                          id="username"
                          placeholder="Nome de usuário"
                          type="text"
                          {...register("username")}
                          autoComplete="off"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Meu Sputi - Identificador
                        </label>
                        <input
                          className="form-control-alternative custom-input"
                          defaultValue={
                            user.resources?.length
                              ? user.resources[0].external_id
                              : null
                          }
                          id="meuSputiExternalId"
                          placeholder="Necessário apenas para usuários do meu sputi"
                          type="text"
                          {...register("meuSputiExternalId")}
                          autoComplete="off"
                        />
                      </FormGroup>

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Senha{" "}
                          <i className="fas fa-info-circle" id="passwordInfo" />
                          <Tooltip
                            placement="right"
                            isOpen={tooltipOpen}
                            target="passwordInfo"
                            toggle={toggleTooltip}
                          >
                            <div style={{ margin: "0", padding: "0" }}>
                              <p style={{ margin: "0", padding: "0" }}>
                                - Mínimo 8 caracteres
                              </p>
                              <p style={{ margin: "0", padding: "0" }}>
                                - Pelo menos uma letra minúscula
                              </p>
                              <p style={{ margin: "0", padding: "0" }}>
                                - Pelo menos uma letra maiúscula
                              </p>
                              <p style={{ margin: "0", padding: "0" }}>
                                - Pelo menos um número
                              </p>
                              <p style={{ margin: "0", padding: "0" }}>
                                - Pelo menos um caractere especial
                              </p>
                              <p style={{ margin: "0", padding: "0" }}>
                                - Não pode ser a palavra &apos;senha&apos;
                              </p>
                              <p style={{ margin: "0", padding: "0" }}>
                                - Não pode conter sequências
                              </p>
                            </div>
                          </Tooltip>
                        </label>
                        <input
                          className="form-control-alternative custom-input"
                          id="password"
                          type="password"
                          autoComplete="new-password"
                          {...register("password")}
                          onChange={handleUpdatePassword}
                        />
                        {errors.password && (
                          <span className="text-danger">
                            *{errors.password.message}
                          </span>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-topic"
                        >
                          Cargos
                        </label>
                        <Select
                          defaultValue={selectedRoles}
                          isMulti
                          name="colors"
                          options={availableRoles}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Atribuir cargos"
                          value={selectedRoles}
                          onChange={setSelectedRoles}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Button type="submit">
                  {hasId ? "Atualizar" : "Adicionar"}
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default UserForm;
