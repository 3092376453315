import { useEffect, useState } from "react";
import { Modal, Col, Button } from "reactstrap";

const ModalPromptRulesDetails = ({ scoreRule }) => {
  const [toggle, setToggle] = useState(false);
  const [formattedRules, setFormattedRules] = useState([]);
  useEffect(() => {
    if (scoreRule?.length) {
      const rules = [];
      for (let i = 0; i < scoreRule.length; i++) {
        const formattedRule = {
          priority: scoreRule[i].priority,
          score: scoreRule[i].score,
          rule: formattingRules(scoreRule[i].rule),
        };
        rules.push(formattedRule);
      }
      setFormattedRules(rules);
    }
  }, [scoreRule]);

  function formattingRules(rules) {
    let formattedRule = "";
    rules.forEach((rule, index) => {
      if (rule.length < 1) {
        formattedRule += ` <b>${rule[index].key}</b> ${translateCondition(
          rule[index].type,
        )} ${rule[index].value}`;
      } else if (rule.length > 1) {
        rule.forEach((r, i) => {
          formattedRule += ` <b>${r.key}</b> ${translateCondition(r.type)} ${
            r.value
          } `;
          if (rule[i + 1]) {
            formattedRule += rule[i + 1].length ? " E " : " OU ";
          }
        });
      } else {
        formattedRule += ` <b>${rule[0].key}</b> ${translateCondition(
          rule[0].type,
        )} ${rule[0].value} `;
      }
      if (rules[index + 1]) {
        formattedRule += rules[index + 1].length ? " E " : " OU ";
      }
    });
    return formattedRule;
  }

  function translateCondition(condition) {
    const conditionMap = {
      GREATER_THAN_OR_EQUAL: "Maior ou igual a",
      LESS_THAN_OR_EQUAL: "Menor ou igual a",
      GREATER_THAN: "Maior que",
      LESS_THAN: "Menor que",
      EQUAL_TO: "Igual a",
      NOT_EQUAL_TO: "Diferente de",
      NULL: "Nulo ou vazio",
    };
    return conditionMap[condition] || "";
  }

  return (
    <Col lg="12" style={{ padding: 0 }}>
      <Button
        className="Button"
        size="sm"
        color="primary"
        onClick={() => setToggle(true)}
      >
        {scoreRule?.length === 0 || !scoreRule
          ? "Sem regras"
          : scoreRule?.length === 1
            ? "1 regra"
            : scoreRule?.length + " regras"}
      </Button>
      <Modal
        className="modal-dialog modal-xl"
        style={{ marginTop: "100px" }}
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {formattedRules?.length ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Regra</th>
                  <th>Classificação</th>
                  <th>Peso esperado</th>
                </tr>
              </thead>
              <tbody>
                {formattedRules.map((rule, index) => (
                  <tr key={index}>
                    <td>
                      {scoreRule?.[0]?.isContestation ? (
                        <div>Item Contestado</div>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: rule.rule }} />
                      )}
                    </td>
                    <td>{rule.priority ? "Crítico" : "Processual"}</td>
                    <td>{rule.score || ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            "Não há regras configuradas."
          )}
        </div>
        <div className="modal-body">
          <Button type="button" onClick={() => setToggle((v) => !v)}>
            Fechar
          </Button>
        </div>
      </Modal>
    </Col>
  );
};

export default ModalPromptRulesDetails;
