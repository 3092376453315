import { useState } from "react";
import { Modal, Col, Button } from "reactstrap";
import { ReactComponent as CheckIcon } from "assets/icons/check_icon.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross_icon.svg";

const Modals = ({ scoreRule }) => {
  const [toggle, setToggle] = useState(false);
  const formattedRules = scoreRule?.length
    ? scoreRule?.map((rule) => ({
        finalScore: rule.score,
        priority: rule.priority,
        passed: rule.passed,
        ruleScore: rule.rule
          .map((rule) => {
            if (rule.length) return rule[0].score;
            return rule.score;
          })
          .reduce((acc, cur) => acc + cur, 0),
        rule: formattingRules(rule.rule),
      }))
    : [];

  function formattingRules(rules) {
    let formattedRule = "";
    rules.forEach((rule, index) => {
      if (rule.length < 1) {
        formattedRule += ` <b>${rule[index].key}</b> ${translateCondition(
          rule[index].type,
        )} ${rule[index].value}`;
      } else if (rule.length > 1) {
        rule.forEach((r, i) => {
          formattedRule += ` <b>${r.key}</b> ${translateCondition(r.type)} ${
            r.value
          } `;
          if (rule[i + 1]) {
            formattedRule += rule[i + 1].length ? " E " : " OU ";
          }
        });
      } else {
        formattedRule += ` <b>${rule[0].key}</b> ${translateCondition(
          rule[0].type,
        )} ${rule[0].value} `;
      }
      if (rules[index + 1]) {
        formattedRule += rules[index + 1].length ? " E " : " OU ";
      }
    });
    return formattedRule;
  }

  function translateCondition(condition) {
    const conditionMap = {
      GREATER_THAN_OR_EQUAL: "Maior ou igual a",
      LESS_THAN_OR_EQUAL: "Menor ou igual a",
      GREATER_THAN: "Maior que",
      LESS_THAN: "Menor que",
      EQUAL_TO: "Igual a",
      NOT_EQUAL_TO: "Diferente de",
      NULL: "Nulo ou vazio",
    };
    return conditionMap[condition] || "";
  }

  return (
    <Col lg="12" style={{ padding: 0 }}>
      <Button
        className="Button"
        size="sm"
        color="primary"
        onClick={() => setToggle(true)}
      >
        Visualizar
      </Button>
      <Modal
        className="modal-dialog modal-xl"
        style={{ marginTop: "100px" }}
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h3>Pesos das chaves após análise</h3>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {formattedRules?.length ? (
            <table className="table table-striped">
              <thead>
                <tr className="checked-table">
                  <th>Regra</th>
                  <th>Classificação</th>
                  <th>Nota recebida</th>
                  <th>Peso esperado</th>
                  <th>Peso recebido</th>
                </tr>
              </thead>
              <tbody>
                {formattedRules.map((rule, index) => (
                  <tr key={index} className="checked-table">
                    <td>
                      {scoreRule?.[0]?.isContestation ? (
                        <div>Item Contestado</div>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: rule.rule }} />
                      )}
                    </td>
                    <td>{rule.priority ? "Crítico" : "Processual"}</td>
                    <td>
                      {rule.passed ? (
                        <CheckIcon style={{ width: "30px", height: "26px" }} />
                      ) : (
                        <CrossIcon style={{ width: "40px", height: "40px" }} />
                      )}
                    </td>
                    <td>{rule.ruleScore || ""}</td>
                    <td>{rule.finalScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            "Não há regras processadas a serem exibidas no momento."
          )}
        </div>
        <div className="modal-body">
          <Button type="button" onClick={() => setToggle((v) => !v)}>
            Fechar
          </Button>
        </div>
      </Modal>
    </Col>
  );
};

export default Modals;
