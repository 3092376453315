import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "service/api";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import ModalConfirm from "../components/Modal/ModalConfirm";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  CardTitle,
  Col,
  CardBody,
} from "reactstrap";

const Content = () => {
  const { register, handleSubmit, reset } = useForm();
  const [, setCollapseOpen] = useState();
  const [range, setRange] = useState(0);
  const [info, setInfo] = useState([]);
  const { actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [, setOnLoad] = useState(false);
  const [, setTopicNames] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [isdisabled, setIsdisabled] = useState(false);
  const permissions = localStorage.getItem("permissions");

  const contentStatus = {
    pending: "Pendente",
    READY: "Pronto",
    FAILED: "Falhou",
  };

  const getContents = async () => {
    setOnLoad(true);

    await api
      .get(`/contents?page=${offset}&limit=${Number(limit)}&${search}`)
      .then((response) => response.data)
      .then((response) => {
        setRange(response.totalPages);
        setTotalCount(response.totalCount);
        const contents = response.contents.map((item) => {
          const created = new Date(item.createdAt).toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          const updated = new Date(item.updatedAt).toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return {
            id: item?.id,
            assistant: item?.assistant?.name,
            client: item?.client?.name,
            topic: item?.topicName,
            status: item?.status,
            content: item?.text,
            createdAt: created,
            updatedAt: item.updatedAt,
            updatedAtFormatted: updated,
          };
        });

        setInfo(contents);

        const topicNames = contents.map((item) => item.topic);
        const topicNamesSet = [...new Set(topicNames)];
        setTopicNames(topicNamesSet);

        api
          .get(`/assistants?page=${offset}&limit=${Number(limit)}`)
          .then((response) => response.data)
          .then((response) => {
            const assistants = response.assistants.map((item) => {
              return item.name;
            });
            setAssistants(assistants);
          })
          .catch((error) => {
            console.error(error);
            toast.error("Erro ao recuperar conteúdos!");
            return;
          });
        setOnLoad(false);
      })
      .catch((error) => {
        if (error.response?.status !== 401) {
          toast.error("Erro ao recuperar conteúdos!");
          return;
        }
        return;
      });
  };

  useEffect(() => {
    getContents();
  }, [offset, search, limit]);

  const handleDelete = (id) => {
    setIsdisabled(true);
    api
      .delete(`/contents/${id}`)
      .then(() => {
        toast.success("Conteúdo deletado com sucesso!");
        const infoFilter = info.filter((item) => item.id !== id);
        setInfo(infoFilter);
        setIsdisabled(false);
      })
      .catch(() => {
        toast.error("Erro ao deletar conteúdo!");
        setIsdisabled(false);
      });
  };

  const onSubmit = (data) => {
    const { topic, assistant } = data;
    let query = "";
    if (topic) {
      query += `topic=${topic}&`;
    }
    if (assistant) {
      query += `assistantName=${assistant}&`;
    }
    setSearch(query);
  };

  const clearSearch = () => {
    let query = "";
    setSearch(query);
    reset();
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  function minutesPassedSince(datetimeString) {
    return Math.floor(
      Math.abs((new Date() - new Date(datetimeString)) / 60000),
    );
  }

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-8 pt-3 pt-md-4 d-flex align-items-center justify-content-center flex-column">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)} className="d-flex">
              <label className="text-white mr-2 ml-2">Assistente: </label>
              <select
                name="assistant"
                id="assistants"
                className="Input"
                style={{
                  display: "block",
                  width: "100%",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                }}
                {...register("assistant")}
              >
                <option value="">Selecione</option>
                {assistants.map((assistant, index) => {
                  return (
                    <option value={assistant} key={index}>
                      {assistant}
                    </option>
                  );
                })}
              </select>
              <label className="text-white mr-2 ml-2">Tópico: </label>
              <input
                name="topic"
                id="topic"
                className="Input"
                style={{
                  display: "block",
                  width: "100%",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  border: "1px solid #cad1d7",
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  transition: "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                }}
                {...register("topic")}
              />
              <Button className="Button ml-2" type="submit">
                Buscar
              </Button>
              <Button className="Button ml-2" onClick={clearSearch}>
                Limpar
              </Button>
            </Form>
          </div>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <div className="container-fluid mb-1">
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats ">
                <CardBody className="pb-1">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h6"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Conteúdos
                      </CardTitle>
                      <span className="h2 mb-0">{totalCount}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0 ">
                <CardBody className="pb-1 ">
                  <Row>
                    <div className="d-flex flex-row align-items-center">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 mr-2"
                      >
                        Paginas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{range}</span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex ml-2">
                <select
                  style={{
                    display: "block",
                    width: "80px",
                    height: "35px",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    color: "#8898aa",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #cad1d7",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    transition:
                      "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                    marginLeft: "10px",
                  }}
                  name="limit"
                  id="limit"
                  className="Input"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Assistente</th>
                    <th>Cliente</th>
                    <th>Topico</th>
                    <th>Conteúdo</th>
                    <th>Criado</th>
                    <th>Atualizado</th>
                    <th>Status</th>
                    {permissions?.includes("update:content") && <th>Editar</th>}
                    {permissions?.includes("remove:content") && (
                      <th>Excluir</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>{item.assistant}</td>
                      <td>{item.client}</td>
                      <td title={item.topic}>{item.topic.substring(0, 20)}</td>
                      <td>
                        <div
                          className="text-justify"
                          title={item.content.replace(/(<([^>]+)>)/gi, "")}
                        >
                          {item.content
                            .replace(/(<([^>]+)>)/gi, "")
                            .substring(0, 20)}
                        </div>
                      </td>
                      <td>{item.createdAt}</td>
                      <td>{item.updatedAtFormatted}</td>
                      <td>{contentStatus[item.status]}</td>
                      {permissions?.includes("update:content") && (
                        <td>
                          {minutesPassedSince(item.updatedAt) <= 1 ? (
                            <p style={{ fontSize: "0.875rem" }}>Indisponível</p>
                          ) : (
                            <NavLink
                              to={`/admin/formulario-conteudo?id=${item.id}`}
                              onClick={closeCollapse}
                              activeClassName="active"
                            >
                              Editar
                            </NavLink>
                          )}
                        </td>
                      )}
                      {permissions?.includes("remove:content") && (
                        <td>
                          <ModalConfirm
                            id={item.id}
                            modalText={`Esta ação irá excluir o conteúdo ${item.content
                              .replace(/(<([^>]+)>)/gi, "")
                              .substring(0, 20)}, você tem certeza?`}
                            handler={() => {
                              handleDelete(item.id);
                            }}
                            disableButton={
                              item.status === "pending" ||
                              minutesPassedSince(item.updatedAt) <= 1
                            }
                            icon={"fas fa-trash"}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    total={range}
                    offset={offset}
                    setOffset={setOffset}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Content;
